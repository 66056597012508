<template>

    <div class="main">
        <agent-info-comp></agent-info-comp>
        <div class="search">
            <div class="refresh" @click="getUserList"><i class="fa fa-refresh"></i></div>
            <!--파트너 선택-->
            <partner-sub-selector-comp @setPartnerId="setPartnerId"></partner-sub-selector-comp>

            <!--검색조건선택-->
            <el-select size="mini" style="width: 100px;margin-left: 5px" v-model="searchUser.byField"
                       placeholder="검색조건">
                <el-option :value="agentConst.User.SEARCH_BY_NICKNAME" label="닉네임"></el-option>
            </el-select>
            <el-input v-model="searchUser.searchString" size="mini" style="width: 150px;margin-left: 5px"
                      placeholder="검색내용입력"></el-input>
            <el-button type="primary" size="mini" style="margin-left: 5px" @click="seachUser">검색</el-button>

            <!--상태-->
            <el-select size="mini" style="width: 80px;margin-left: 5px" v-model="selectedStatus"
                       @change="changeStatus" placeholder="회원상태 선택">
                <el-option :value="agentConst.User.STATUS_ENABLE" label="정상">정상</el-option>
                <el-option :value="agentConst.User.STATUS_DISABLE" label="블럭">블럭</el-option>
            </el-select>
        </div>

            <table class="agent_table">
                <tr>
                    <th>상태</th>
                    <th>총판</th>
                    <th>아이디</th>
                    <th>닉네임</th>
                    <th>예금주</th>
                    <th>레벨</th>
                    <th>보유금액</th>
                    <th>보유Point</th>
                    <th>가입일짜</th>
                    <th>총입금</th>
                    <th>총출금</th>
                    <th>최근접속</th>
                </tr>
                <tr v-for="item in userList">
                    <td>
                        <div v-if="item.status == agentConst.User.STATUS_ENABLE">[정상]</div>
                        <div style="color: red" v-if="item.status == agentConst.User.STATUS_DISABLE">
                            [블럭]
                        </div>
                        <div style="color: #b3d8ff"
                             v-if="item.status == agentConst.User.STATUS_UNAUTHORIZED">[인증]
                        </div>
                    </td>
                    <td>
                        <div v-if="null != item.agent"
                             @click="editPartner(item.partnerId,agentConst.MODE_EDIT)" style="cursor: pointer">
                            {{item.agent.nickname}}
                        </div>
                    </td>
                    <td>
                        {{item.username}}
                    </td>
                    <td>
                        {{item.nickname}}
                    </td>
                    <td>
                        {{item.beneficiary}}
                    </td>
                    <td>
                        {{item.rank.rankName}}
                    </td>
                    <td>
                        <span :class="{'text-blue': item.cash > 100000}">{{item.cash|comma}}</span>
                    </td>
                    <td>
                        <span :class="{'text-blue': item.userPoint > 100000}">{{item.userPoint|comma}}</span>
                    </td>
                    <td>
                        <span :class="{'bg-warning' : item.autoclosed === agentConst.NOT}">{{item.createTime|datef('MM월 DD일')}}</span>
                    </td>
                    <td> {{item.rechargeTotal|comma}}</td>
                    <td style="color: red">
                        {{(item.extractTotal * (-1))|comma}}
                    </td>
                    <td> {{item.lastLoginTime|datef('MM월DD일 HH시mm분')}}</td>
                </tr>
            </table>

        <div class="pagePanel">
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pageNum"
                    :page-sizes="[50, 100, 150, 200]"
                    :page-size="50"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pageTotal">
            </el-pagination>
        </div>
    </div>


</template>

<script>
    import OnlineUserComp from "../../components/administrator/OnlineUserComp";
    import TodayStatisticComp from "../../components/administrator/TodayStatisticComp";
    import ManagerTopbarComp from "../../components/administrator/ManagerTopbarComp";

    import {Loading} from "element-ui";
    import PartnerSelectorComp from "../../components/administrator/PartnerSelectorComp";

    import PartnerSubSelectorComp from "../../components/agent/PartnerSubSelectorComp";
    import {agentMixin} from "../../common/agent/agentMixin";
    import agentConst from "../../common/agent/agentConst";
    import {getUserList2} from "../../network/agent/commonRequest";
    import AgentInfoComp from "../../components/agent/AgentInfoComp";

    export default {
        name: "AgentUser",
        components: {
            AgentInfoComp,
            PartnerSubSelectorComp,
            PartnerSelectorComp,
            ManagerTopbarComp,
            TodayStatisticComp,
            OnlineUserComp
        },
        mixins: [agentMixin],
        data() {
            return {
                user: {
                    status: agentConst.User.STATUS_ENABLE,
                },
                searchUser: {},
                pageNum: 1,
                pageSize: 20,
                orderBy: 'id DESC',
                pageTotal: 0,
                userList: [],
                editUserId: 0,
                selectedPartnerId: null,
                selectedType: {},
                selectedRank: null,
                selectedStatus: agentConst.User.STATUS_ENABLE,

            }
        },
        methods: {

            getUserList() {
                let loadingInstance = Loading.service({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                getUserList2(this.user, this.pageNum, this.pageSize, this.orderBy).then(res => {
                    this.pageTotal = res.data.total;
                    this.userList = res.data.data;
                    loadingInstance.close();
                })
            },
            handleSizeChange(val) {
                this.pageNum = 1;
                this.pageSize = val;
                this.getUserList();
            },
            handleCurrentChange(val) {
                this.pageNum = val;
                this.getUserList();
            },
            setPartnerId(partnerId) {
                this.pageNum = 1
                this.user = {}
                this.user.status = agentConst.User.STATUS_ENABLE
                this.user.partnerId = partnerId
                this.getUserList()
            },
            seachUser() {
                this.user = this.searchUser
                this.getUserList()
            },
            changeStatus() {
                this.user = {}
                this.user.status = this.selectedStatus
                this.getUserList()
            },

        },
        created() {
            this.getUserList()
        },
        watch: {}
    }
</script>

<style scoped>

</style>